<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading text-uppercase">
            TRAVEL REQUEST &nbsp; &nbsp;</span
          >
          <v-btn :color="getStatusColor(status)" rounded
            ><span style="font-family: kumbhRegular">{{ status }}</span>
            <span
              v-if="travelView.isTicketUnderProcess"
              style="font-size: 12px !important; color: green"
              >Ticket Under Processing</span
            ></v-btn
          >
        </v-card-title>
        <v-layout wrap justify-start>
          <v-flex xs12>
            <v-layout wrap>
              <v-flex xs12 sm2 md2 pa-5>
                <v-layout wrap pt-2>
                  <v-flex xs12 v-if="previewUrl">
                    <v-avatar>
                      <v-img :src="mediaURL + previewUrl"></v-img>
                    </v-avatar>
                  </v-flex>
                  <v-flex xs12 v-else>
                    <v-avatar color="indigo" size="80">
                      <v-icon dark x-large> mdi-account-circle </v-icon>
                    </v-avatar>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex
                xs12
                sm10
                md10
                pa-0
                pa-xs-0
                pa-sm-2
                pa-md-5
                pa-lg-5
                pa-xl-5
              >
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Employee Name</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2">{{ fullName }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Employee ID</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2">{{ empID }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Email ID</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2">{{ email }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Employee Phone</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2">{{ phone }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Employee Position</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left v-if="travelView.uid">
                        <span class="itemText2">{{
                          travelView.uid.roleName
                        }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Applied Date</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2">{{
                          formatDate(travelView.create_date)
                        }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Source</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2">{{ travelView.source }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Destination</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2">{{
                          travelView.destination
                        }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Journey Starts on</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2"
                          >{{ formatDate(travelView.startingTime)
                          }}{{
                            convertUTCToLocal(travelView.startingTime)
                          }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Journey Ends on</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2"
                          >{{ formatDate(travelView.endingTime)
                          }}{{ convertUTCToLocal(travelView.endingTime) }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Travel Mode</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2">{{
                          travelView.travelMode
                        }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Description</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2">{{
                          travelView.description
                        }}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Approved By</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2" v-if="travelView.assignedBy">{{
                          travelView.assignedBy.name
                        }} ( {{
                          travelView.assignedBy.employeeId
                        }})</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-layout wrap py-3>
                      <v-flex xs5 sm5 text-left>
                        <span class="itemHeading2">Assigned To</span>
                      </v-flex>
                      <v-flex xs7 sm7 text-left>
                        <span class="itemText2" v-if="travelView.assignedTo">{{
                          travelView.assignedTo.name
                        }}( {{
                          travelView.assignedTo.employeeId
                        }})</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 px-5>
                <v-layout wrap>
                  <v-flex
                    xs12
                    v-if="travelView.billCopy && travelView.billCopy.length > 0"
                  >
                    <v-layout wrap v-if="travelView.billCopy.length > 0">
                      <span class="itemHeading">Bills</span>
                      <v-flex
                        xs12
                        sm6
                        md4
                        pt-4
                        v-for="(item, index) in travelView.billCopy"
                        :key="index"
                      >
                        <a
                          :href="baseURL + '/i/' + item"
                          target="_blank"
                          type="image"
                          download="a.jpg"
                          :key="item"
                        >
                          <v-img
                            :src="mediaURL + item"
                            style="
                              width: 120px;
                              height: 120px;
                              objet-fit: cover;
                            "
                          >
                          </v-img> </a
                      ></v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex
                    xs12
                    v-if="
                      travelView.ticketCopy && travelView.ticketCopy.length > 0
                    "
                  >
                    <v-layout wrap v-if="travelView.ticketCopy.length > 0">
                      <v-flex xs12 text-left>
                      <span class="itemHeading">Tickets</span>
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md4
                        pt-5
                        v-for="(item, index) in travelView.ticketCopy"
                        :key="index"
                      >
                        <a
                          :href="baseURL + '/i/' + item"
                          target="_blank"
                          type="image"
                          download="a.jpg"
                          :key="item"
                        >
                          <v-img
                            :src="mediaURL + item"
                            style="
                              width: 120px;
                              height: 120px;
                              objet-fit: cover;
                            "
                          >
                          </v-img> </a
                      ></v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start>
                  <v-flex
                    xs12
                    sm6
                    px-2
                    text-left
                    v-if="
                      travelView.startingPhotos &&
                      travelView.startingPhotos.length > 0
                    "
                  >
                    <v-layout wrap v-if="travelView.startingPhotos.length > 0">
                      <v-flex xs12>
                        <span class="itemHeading">Starting Details</span>
                      </v-flex>
                      <v-flex xs12 v-if="travelView.startingRemarks">
                        <span class="itemText2">{{
                          travelView.startingRemarks
                        }}</span>
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md4
                        pt-5
                        v-for="(item, index) in travelView.startingPhotos"
                        :key="index"
                      >
                        <a
                          :href="baseURL + '/i/' + item"
                          target="_blank"
                          type="image"
                          download="a.jpg"
                          :key="item"
                        >
                          <v-img
                            :src="mediaURL + item"
                            style="
                              width: 120px;
                              height: 120px;
                              objet-fit: cover;
                            "
                          >
                          </v-img> </a
                      ></v-flex>
                    </v-layout>
                  </v-flex>
                  <!-- </v-layout>
                <v-layout wrap justify-start py-5> -->
                  <v-flex
                    xs12
                    sm6
                    px-2
                    text-left
                    v-if="
                      travelView.endingPhotos &&
                      travelView.endingPhotos.length > 0
                    "
                  >
                    <v-layout wrap v-if="travelView.endingPhotos.length > 0">
                      <v-flex xs12>
                        <span class="itemHeading">Ending Details</span>
                      </v-flex>
                      <v-flex xs12 v-if="travelView.endingRemarks">
                        <span class="itemText2">{{
                          travelView.endingRemarks
                        }}</span>
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md4
                        pt-5
                        v-for="(item, index) in travelView.endingPhotos"
                        :key="index"
                      >
                        <a
                          :href="baseURL + '/i/' + item"
                          target="_blank"
                          type="image"
                          download="a.jpg"
                          :key="item"
                        >
                          <v-img
                            :src="mediaURL + item"
                            style="
                              width: 120px;
                              height: 120px;
                              objet-fit: cover;
                            "
                          >
                          </v-img> </a
                      ></v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap px-5 v-if="this.status == 'pending'">
              <v-flex xs6 align-self-center text-right pa-4>
                <v-dialog persistent v-model="approveDialog" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      style="font-family: kumbhBold; border: 2px solid #005f32"
                      class="mr-2"
                      plain
                      depressed
                      dark
                      block
                      v-on="on"
                      v-bind="attrs"
                    >
                      <span style="color: #005f32">Approve</span>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-layout wrap justify-center>
                      <v-flex
                        xs12
                        align-self-center
                        pa-4
                        style="background: #005f32"
                        text-left
                      >
                        <span class="kumbhBold" style="color: #ffffff"
                          >Confirmation</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-card-title
                      >Are you sure you want to approve this
                      request?</v-card-title
                    >
                    <v-layout wrap justify-center>
                      <v-flex
                        xs11
                        sm11
                        text-left
                        v-if="
                          travelView.travelMode == 'Flight' ||
                          travelView.travelMode == 'Train' ||
                          travelView.travelMode == 'Bus'
                        "
                      >
                        <span class="kumbhRegular"
                          >Please select an officer</span
                        >
                        <v-select
                          :items="assignedEmployees"
                          dense
                          v-model="assignedTo"
                          item-text="name"
                          item-value="_id"
                          outlined
                        ></v-select>
                      </v-flex>
                    </v-layout>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        tile
                        dark
                        @click="approveDialog = false"
                        >Cancel</v-btn
                      >
                      &nbsp;
                      <v-btn
                        color="#005f32"
                        outlined
                        tile
                        @click="approve('assigned')"
                        >Approve</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-flex>

              <v-flex xs6 align-self-center text-left pa-4>
                <v-dialog persistent v-model="rejectDialog" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      style="font-family: kumbhBold; border: 2px solid red"
                      class="mr-2"
                      plain
                      block
                      depressed
                      dark
                      v-on="on"
                      v-bind="attrs"
                    >
                      <span style="color: red">Reject</span>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-layout wrap>
                      <v-flex
                        xs12
                        align-self-center
                        pa-4
                        style="background: #005f32"
                        text-left
                      >
                        <span class="kumbhBold" style="color: #ffffff"
                          >Confirmation</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-card-title
                      >Are you sure you want to reject this
                      request?</v-card-title
                    >
                    <v-layout wrap pa-6>
                      <!-- <v-flex
                        xs12
                        sm12
                        text-left
                        v-if="
                          travelView.travelMode == 'Flight' ||
                          travelView.travelMode == 'Train' ||
                          travelView.travelMode == 'Bus'
                        "
                      >
                        <span class="kumbhRegular"
                          >Please select an officer</span
                        >
                        <v-select
                          :items="assignedEmployees"
                          dense
                          v-model="assignedTo"
                          item-text="name"
                          item-value="value"
                          outlined
                        ></v-select>
                      </v-flex> -->
                      <v-flex xs12 align-self-center>
                        <v-textarea
                          label="Remarks"
                          v-model="remarks"
                          outlined
                          hide-details
                        ></v-textarea>
                      </v-flex>
                    </v-layout>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        tile
                        dark
                        @click="rejectDialog = false"
                        >Cancel</v-btn
                      >
                      &nbsp;
                      <v-btn
                        color="#005f32"
                        outlined
                        tile
                        @click="approve('rejected')"
                        >Reject</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start px-5>
              <v-flex xs12 text-left py-5>
                <span style="font-family: kumbhSemibold; font-size: 22px"
                  >Time Logs</span
                >
              </v-flex>
              <v-flex xs12 sm8 >
                <v-timeline :dense="$vuetify.breakpoint.smAndDown">
                  <v-timeline-item color="#005f32" small v-for="info in travelView.logs" :key="info._id">
                    <v-card>
                      <v-card-title class="text-h6">
                        <span style="font-family: kumbhSemibold"
                          >{{ info.status }}
                        </span>
                      </v-card-title>
                      <v-row>
                        <v-col cols="12" md="12" v-if="info">
                          <span class="itemText2">
                            {{ formatDate(info.statusTime) }}
                            {{ convertUTCToLocal(info.statusTime) }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";
export default {
  components: {
    VueElementLoading,
  },
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      visible: false,
      create_date: "",
      previewUrl: "",
      data: [],
      empID: "",
      fullName: "",
      email: "",
      phone: "",
      leaveRequiredOn: "",
      leaveCategory: "",
      leaveType: "",
      reason: "",
      status: "",
      position: "",
      remarks: "",
      TL: 15,
      LC: null,
      leaveCategories: [],
      approveDialog: false,
      rejectDialog: false,
      travelView: {},
      assignedEmployees: [],
      assignedTo: "",
    };
  },
  mounted: function () {
    this.getview1();
    this.getUpdates();
  },
  methods: {
    getStatusColor(status) {
      if (status === "approved") {
        return "success";
      }
      if (status == "pending") {
        return "warning";
      }
      if (status == "assigned") {
        return "secondary";
      }
      if (status == "approved") {
        return "secondary";
      }
      if (status == "rejected") {
        return "danger";
      }
      if (status == "pending") {
        return "warning";
      }
      if (status == "started") {
        return "info";
      }
      if (status == "completed") {
        return "success";
      }
    },

    approve(value) {
      this.visible = true;
      var app = {};
      if (this.assignedTo) {
        app["action"] = "assigned";
        app["assignedTo"] = this.assignedTo;
      } else {
        app["action"] = "approved";
      }
      if (value == "rejected") {
        app["action"] = value;
        app["remarks"] = this.remarks;
      }
      app["id"] = this.$route.query.id;
      axios({
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: app,
        url: "/employee/travel/application/approve/reject",
      })
        .then((response) => {
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.$router.push("/Attendance/travelRequests");
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getview1();
          }
        })
        .catch((e) => {
          this.visible = true;
          this.errors.push(e);
        });
    },

    getview1() {
      this.visible = true;
      axios({
        method: "GET",
        url: "/travelLog/view/" + this.$route.query.id,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status === true) {
            this.visible = false;
            this.travelView = response.data.data;
            this.empID = response.data.data.uid.employeeId;
            this.fullName = response.data.data.uid.name;
            this.email = response.data.data.uid.email;
            this.phone = response.data.data.uid.phonenumber;
            this.previewUrl = response.data.data.uid.photo;
            this.status = response.data.data.status;
          } else if (response.data.status === false) {
            alert(response.data.msg);
            this.$router.go(-1);
          }
        })
        .catch((e) => {
          this.visible = false;
          this.errors.push(e);
          this.msg = "failed";
        });
    },
    getUpdates() {
      this.appLoading = true;
      axios({
        url: "/organisation/settings/travelRequest/assignees/list",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.assignedEmployees = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 7) + " " + day + " " + year + " ";
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      if (item) item = item.slice(11, 16);
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
    convertUTCToLocal(utcDateString) {
      // Create a new Date object from the UTC date string
      var utcDate = new Date(utcDateString);

      // Get the time zone offset in minutes
      // const timezoneOffset = utcDate.getTimezoneOffset();
      // console.log("timezoneOffset:", timezoneOffset);

      // Adjust the date for the time zone offset (convert from minutes to milliseconds)
      // utcDate.setMinutes(utcDate.getMinutes() - timezoneOffset);

      // utcDate = new Date(utcDate);

      // Get the year, month, day, hours, minutes, seconds, and milliseconds
      // const year = utcDate.getFullYear();
      // const month = utcDate.getMonth() + 1; // Months are zero-indexed (January is 0)
      // const day = utcDate.getDate();

      // Format the date string (YYYY-MM-DD)
      // const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

      // Get hours, minutes, and format AM/PM indicator
      const hours = utcDate.getHours();
      const minutes = utcDate.getMinutes().toString().padStart(2, "0");
      const amPm = hours >= 12 ? "PM" : "AM";

      // Adjust hours for 12-hour format (convert from 24-hour to 12-hour)
      const adjustedHours = hours % 12 || 12;

      // Format the time string (HH:MM AM/PM)
      const formattedTime = `${adjustedHours}:${minutes} ${amPm}`;

      // Return the date and time as separate objects
      return formattedTime;
      // return {
      //   date: formattedDate,
      //   time: formattedTime
      // };
    },
  },
};
</script>
<style scoped>
.modal-footer {
  display: none !important;
}

td {
  font-weight: bold;
}
</style>